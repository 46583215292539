import { z } from "zod"; // MESSAGES

export var COPY_INVALID_EMAIL = "Email address entered is not valid";
export var COPY_INVALID_PHONE = "Only valid phone numbers are allowed (including area code)";
export var COPY_INVALID_NAME = "Please enter a valid name";
export var COPY_INVALID_DATE = "Please enter a valid date"; // REGEX

var validEmailRegex = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+[^.]$/i;
var validNameRegex = /^[a-z ,.'\-()\u2019]+$/i;

var getValidPhoneNumberRegexAU = function getValidPhoneNumberRegexAU(isMobile) {
  return isMobile ? /^04\d{8,}$/ : /(^(0)[23478]\d{8,}$|^672\d{6,})$/;
};

var getValidPhoneNumberRegexNZ = function getValidPhoneNumberRegexNZ(isMobile) {
  return isMobile ? /^02\d{5,9}$/ : /(^(0)[234679]\d{5,9}$|^02409\d{4})$/;
};

var getValidPhoneNumberRegex = function getValidPhoneNumberRegex(isMobile) {
  var isCotd = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;
  return isCotd ? getValidPhoneNumberRegexAU(isMobile) : getValidPhoneNumberRegexNZ(isMobile);
};

export var getFormValidationSchema = function getFormValidationSchema(_ref) {
  var fields = _ref.fields,
      isMobile = _ref.isMobile,
      isCotd = _ref.isCotd;
  var schemaObjects = {
    email: z.string().min(1, "Email is required").email(COPY_INVALID_EMAIL),
    phone: z.string().regex(getValidPhoneNumberRegex(isMobile, isCotd), COPY_INVALID_PHONE),
    firstName: z.string().min(1, "First name is required").regex(validNameRegex, COPY_INVALID_NAME),
    lastName: z.string().min(1, "Last name is required").regex(validNameRegex, COPY_INVALID_NAME)
  };

  if (!fields || fields.length === 0) {
    return z.object(schemaObjects).required();
  }

  var selectedSchemaObjects = {};
  fields.forEach(function (field) {
    if (schemaObjects[field]) {
      selectedSchemaObjects[field] = schemaObjects[field];
    }
  });
  return z.object(selectedSchemaObjects).required();
};