import _defineProperty from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import { getQueryValue } from "../getQueryValue/getQueryValue";
import { resetQueryString } from "../resetQueryString/resetQueryString"; // Add or update a query string value

export var updateQueryStringValue = function updateQueryStringValue(router, key, value) {
  var allowMultiple = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : true;
  var currentQuery = resetQueryString(_objectSpread({}, router.query));
  var currentValues = getQueryValue(currentQuery[key]);

  if (allowMultiple) {
    // For filters that can have multiple values
    if (!currentValues.includes(value)) {
      currentValues.push(value);
    }
  } else {
    currentValues = [value];
  }

  currentQuery[key] = currentValues.length > 1 ? currentValues : currentValues[0];
  router.push({
    query: currentQuery
  }, undefined, {
    shallow: true
  });
};