import React from "react";
import { ThemeProvider } from "@catchoftheday/theme";
/** Includes all global providers used by Shopfront App */

import { jsx as ___EmotionJSX } from "@emotion/react";
export var GlobalAppProvider = function GlobalAppProvider(_ref) {
  var children = _ref.children;
  return ___EmotionJSX(ThemeProvider, {
    enableGlobalBackgroundConfig: true
  }, children);
};