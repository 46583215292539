import _extends from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
import React from "react";
import { PageContainer } from "@catchoftheday/cg-components";
import { Footer, Header } from "@catchoftheday/header";
import { GlobalAppProvider } from "../contexts/GlobalAppProvider";
import { jsx as ___EmotionJSX } from "@emotion/react";

/**
 * Wraps a Next page with required Context providers and common layout components such as Header and Footer.
 */
export var PageLayout = function PageLayout(_ref) {
  var host = _ref.host,
      children = _ref.children,
      headerProps = _ref.headerProps,
      configFlags = _ref.configFlags;

  var _ref2 = configFlags || {},
      _ref2$minimal_footer_ = _ref2.minimal_footer_enabled,
      minimal_footer_enabled = _ref2$minimal_footer_ === void 0 ? false : _ref2$minimal_footer_,
      _ref2$minimal_header_ = _ref2.minimal_header_enabled,
      minimal_header_enabled = _ref2$minimal_header_ === void 0 ? false : _ref2$minimal_header_;

  return ___EmotionJSX(GlobalAppProvider, null, ___EmotionJSX(Header, _extends({
    host: host,
    isMinimalHeaderEnabled: minimal_header_enabled
  }, headerProps)), ___EmotionJSX(PageContainer, null, children), ___EmotionJSX(Footer, {
    isMinimalFooterEnabled: minimal_footer_enabled
  }));
};