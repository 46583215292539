import _toConsumableArray from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/toConsumableArray.js";
var NUMBER_OF_UNMASKED_DIGITS = 4;
var NUMBER_OF_MASKED_CHARACTERS = 4;
var DEFAULT_MASK_CHARACTER = "•";
/**
 *
 * @param cardNumber Credit Card number as a string
 * @param maskCharacter The character with which the Credit Card number provided will be masked. default: •
 * @returns Formatted Credit Card number e.g. •••• 2222
 */

export var formatCreditCardNumber = function formatCreditCardNumber(cardNumber) {
  var maskCharacter = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : DEFAULT_MASK_CHARACTER;
  return "".concat(_toConsumableArray(Array(NUMBER_OF_MASKED_CHARACTERS)).map(function () {
    return maskCharacter;
  }).join(""), " ").concat(cardNumber.replaceAll(/[^0-9.]/g, "").slice(-NUMBER_OF_UNMASKED_DIGITS));
};