import _extends from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
import _objectWithoutProperties from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/objectWithoutProperties.js";
var _excluded = ["speed", "placeholderProps"];
import React from "react";
import { useTheme } from "@catchoftheday/theme";
import { Box } from "../../Box/Box";
import { ContentPlaceHolder } from "../ContentPlaceHolder";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var ContentPlaceHolderPresetLineBlock = function ContentPlaceHolderPresetLineBlock(_ref) {
  var _ref$speed = _ref.speed,
      speed = _ref$speed === void 0 ? 2 : _ref$speed,
      placeholderProps = _ref.placeholderProps,
      containerProps = _objectWithoutProperties(_ref, _excluded);

  var theme = useTheme();
  return ___EmotionJSX(Box, _extends({
    overflow: "hidden"
  }, containerProps), ___EmotionJSX(ContentPlaceHolder, _extends({
    viewBox: "0 0 2000 2000",
    speed: speed,
    backgroundColor: theme.colors.grey,
    uniqueKey: "content-placeholder-line-block-".concat(Math.round(Math.random() * 1000))
  }, placeholderProps), ___EmotionJSX("rect", {
    width: 2000,
    height: 2000,
    fill: theme.colors.grey
  })));
};