export var getRetailMediaExperiment = function getRetailMediaExperiment(pageType, position) {
  if (pageType === "PRODUCT") {
    return "retail_media_product_page_banner_feb_24";
  }

  if (pageType === "SEARCH" && position === "bottom") {
    return "retail_media_search_banner_bottom_feb_24";
  }

  return "retail_media_search_banner_jan_24";
};