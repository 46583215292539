import _extends from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
import _objectWithoutProperties from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/objectWithoutProperties.js";
import _taggedTemplateLiteral from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";
var _excluded = ["icon", "checkedIcon", "containerProps"];

var _templateObject;

import React from "react";
import { Switch as MuiSwitch, switchClasses } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { styled, theme as catchTheme } from "@catchoftheday/theme";
import { Box } from "../Box";
import { Flex } from "../Flex";
import { IconCross, IconTick } from "../Icon";
import { jsx as ___EmotionJSX } from "@emotion/react";
var customTheme = createTheme();
var CustomizedSwitch = styled(MuiSwitch)(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  width: 39px;\n  height: 24px;\n  padding: 0;\n  & .", " {\n    padding: 0;\n    transform: translate(20%, 17%);\n    &.", " {\n      transform: translate(100%, 17%);\n      & + .", " {\n        opacity: 1;\n        background-color: ", ";\n        border-color: ", ";\n      }\n      .", " {\n        background-color: ", ";\n      }\n    }\n    &.", " {\n      & + .", " {\n        opacity: 0.2;\n      }\n      .", " {\n        opacity: 0.2;\n      }\n      &.", " {\n        .", " {\n          opacity: 1;\n          svg {\n            opacity: 0.2;\n          }\n        }\n      }\n    }\n  }\n  & .", " {\n    background-color: ", ";\n    width: 18px;\n    height: 18px;\n    border-radius: 50%;\n  }\n  & .", " {\n    width: 39px;\n    height: 24px;\n    opacity: 1;\n    background-color: ", ";\n    border-radius: 75px;\n    border: solid 1.5px ", ";\n  }\n"])), switchClasses.switchBase, switchClasses.checked, switchClasses.track, catchTheme.colors.brand.primary, catchTheme.colors.brand.primary, switchClasses.thumb, catchTheme.colors.white, switchClasses.disabled, switchClasses.track, switchClasses.thumb, switchClasses.checked, switchClasses.thumb, switchClasses.thumb, catchTheme.colors.textTertiary, switchClasses.track, catchTheme.colors.ui.backgroundColor, catchTheme.colors.textTertiary);

var SwitchIconWrapper = function SwitchIconWrapper(_ref) {
  var children = _ref.children;
  return ___EmotionJSX(Flex, {
    justifyContent: "center",
    alignItems: "center",
    className: switchClasses.thumb
  }, children);
};

export var Switch = function Switch(_ref2) {
  var icon = _ref2.icon,
      checkedIcon = _ref2.checkedIcon,
      containerProps = _ref2.containerProps,
      rest = _objectWithoutProperties(_ref2, _excluded);

  var defaultIcon = ___EmotionJSX(IconCross, {
    width: 7,
    height: 7
  });

  var defaultCheckedIcon = ___EmotionJSX(IconTick, {
    color: "text",
    width: 9,
    height: 7
  });

  return ___EmotionJSX(Box, containerProps, ___EmotionJSX(ThemeProvider, {
    theme: customTheme
  }, ___EmotionJSX(CustomizedSwitch, _extends({
    icon: ___EmotionJSX(SwitchIconWrapper, null, icon || defaultIcon),
    checkedIcon: ___EmotionJSX(SwitchIconWrapper, null, checkedIcon || defaultCheckedIcon)
  }, rest))));
};
Switch.displayName = "Switch";
export default Switch;