import _extends from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
import _objectWithoutProperties from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/objectWithoutProperties.js";
import _taggedTemplateLiteral from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";
var _excluded = ["title", "url", "image", "badgeText"];

var _templateObject;

import React from "react";
import { styled } from "@catchoftheday/theme";
import { Box } from "../Box";
import { Flex } from "../Flex";
import { ImageResponsive } from "../ImageResponsive";
import { Link } from "../Link";
import { Text } from "../Text";
import { Truncate } from "../Truncate";
import { jsx as ___EmotionJSX } from "@emotion/react";
var EventCardContainer = styled(Box)(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  a,\n  a:hover p {\n    text-decoration: none !important;\n    color: initial;\n  }\n"])));
export var EventCard = function EventCard(_ref) {
  var title = _ref.title,
      url = _ref.url,
      image = _ref.image,
      badgeText = _ref.badgeText,
      containerProps = _objectWithoutProperties(_ref, _excluded);

  return ___EmotionJSX(EventCardContainer, _extends({
    border: "solid 1px",
    borderColor: "ui.borderColor"
  }, containerProps), ___EmotionJSX(Link, {
    href: url
  }, ___EmotionJSX(ImageResponsive, {
    src: image.url,
    alt: image.altText,
    lazyLoad: true,
    ratio: 66.67
  }), ___EmotionJSX(Flex, {
    textAlign: "center",
    justifyContent: "center",
    height: "105px",
    position: "relative",
    overflow: "hidden",
    flex: "1 1 auto",
    flexDirection: "column"
  }, ___EmotionJSX(Text, {
    fontSize: "1.25rem",
    fontWeight: "normal",
    m: "0 20px",
    color: "typography.text"
  }, ___EmotionJSX(Truncate, {
    lines: 2,
    fontSize: "inherit",
    fontWeight: "inherit"
  }, title)), badgeText && ___EmotionJSX(Text, {
    fontSize: "0.875rem",
    textTransform: "uppercase",
    m: "0 20px",
    color: "typography.subText"
  }, ___EmotionJSX(Truncate, {
    fontSize: "inherit",
    fontWeight: "inherit"
  }, badgeText)))));
};