import { trackSelfDescribingEvent } from "@snowplow/browser-tracker";
export function emitCatalogueView(_ref) {
  var listName = _ref.listName,
      filters = _ref.filters,
      searchQuery = _ref.searchQuery,
      sortTypeId = _ref.sortTypeId,
      page = _ref.page,
      results = _ref.results,
      adultSearch = _ref.adultSearch,
      offerIds = _ref.offerIds;
  trackSelfDescribingEvent({
    event: {
      schema: "iglu:au.com.catch/catalogue_view/jsonschema/4-1-0",
      data: {
        list_name: listName,
        filters: filters,
        search_query: searchQuery,
        sort_type_id: sortTypeId,
        page: page,
        results: results,
        adult_search: adultSearch,
        offer_ids: offerIds || null
      }
    }
  });
}