export var getEventTitles = function getEventTitles(event) {
  var onePassTitle = event.onePassTitle,
      title = event.title,
      onePassSubtext = event.onePassSubtext,
      pillText = event.pillText;
  var titleUsed = onePassTitle !== null && onePassTitle !== void 0 && onePassTitle.length ? onePassTitle : title;
  var subtitleUsed = onePassSubtext !== null && onePassSubtext !== void 0 && onePassSubtext.length ? onePassSubtext : pillText;
  return {
    title: titleUsed,
    subtitle: subtitleUsed !== null && subtitleUsed !== void 0 ? subtitleUsed : null
  };
};
export var getRecommendedProductsRecommenderTitle = function getRecommendedProductsRecommenderTitle(_ref) {
  var experimentBucket = _ref.experimentBucket,
      loading = _ref.loading;
  if (loading) return "";

  if (experimentBucket === 1) {
    return "Suggested For You";
  } else if (experimentBucket === 2) {
    return "Just For You";
  }

  return "Recommended For You";
};