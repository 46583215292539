import _extends from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
import React from "react";
import { Link, Text } from "@catchoftheday/cg-components";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var SalesRulePromotionCopy = function SalesRulePromotionCopy(_ref) {
  var description = _ref.description,
      linkText = _ref.linkText,
      eventSlug = _ref.eventSlug,
      containerProps = _ref.containerProps,
      onLinkClick = _ref.onLinkClick;
  var regPattern = new RegExp("".concat(linkText), "i");
  var copyArr = description ? description.split(regPattern) : [];

  if (eventSlug && copyArr.length > 1) {
    return ___EmotionJSX(Text, _extends({
      "aria-label": "sales promotion cart text",
      fontSize: "small",
      color: "white",
      fontWeight: "semibold",
      my: 0
    }, containerProps), copyArr[0], ___EmotionJSX(Link, {
      href: "/event/".concat(eventSlug),
      textDecoration: "underline",
      color: "inherit",
      onClick: onLinkClick
    }, linkText), copyArr[1]);
  }

  return ___EmotionJSX(Text, _extends({
    "aria-label": "sales promotion cart text",
    fontSize: "small",
    color: "white",
    fontWeight: "semibold",
    m: 0
  }, containerProps), description);
};