import { createSvgIconComponent } from "../utils";
export var IconShopbagPlus = createSvgIconComponent({
  paths: [{
    d: "M9.41 9.95V6.807c0-1.578 1.343-2.857 3-2.857s3 1.28 3 2.857V9.95",
    props: {
      stroke: "currentColor",
      strokeLinecap: "round",
      strokeWidth: "2",
      fill: "none"
    }
  }, {
    d: "M11.41 21.95h-4a2 2 0 0 1-2-2v-12h14v6M16.41 15.95v6M13.41 18.95h6",
    props: {
      stroke: "currentColor",
      strokeLinecap: "round",
      strokeWidth: "2",
      fill: "none"
    }
  }],
  fill: "currentColor",
  viewBox: "0 0 25 25"
});